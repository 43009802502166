import React from "react"

class DevMethod extends React.Component {
  state = {
    modal: false,
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    return (
      <div className="pt-100">
      <section className="devmethod gray-bg">
        <div className="container">
          <h2 className="heading-h2 text-center">
          NFT Marketplace Development <span className="bluecolor">Methods We Offer</span>
          </h2>
          <div className="d-lg-flex">
              <div className="square" >
                <div className='head3'>
                  <img
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/development-from-scratch.png"
                    alt="Development from Scratch"
                  />
                  Development from Scratch
                </div>
                <p className="pharagraph">
                When you opt to build an NFT Marketplace from scratch, the requirements start from the beginning. We prepare the entire code without the usage of any outside tools and any unique ideas can be implemented. This NFT Marketplace development method is long, complex and requires attention to detail throughout the process.
                </p>
              </div>
              <div className="square">
                <div className='head3'>
                  <img
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/hire-our-developers.png"
                    alt="White Label NFT Marketplace"
                  />
                  White Label NFT Marketplace Development
                </div>
                <p className="pharagraph">
                The white label NFT Marketplace is a ready-made solution for startups who want to launch an NFT-based platform instantly. The software doesn’t require much coding knowledge and is already tested. Our white label services for NFT Marketplace development help in creating a brand for themselves without much effort.
                </p>
              </div>
              <div className="square" >
                <div className='head3'>
                  <img
                    width={55}
                    height={55}
                    src="https://coinsclone.mo.cloudinary.net/images/cryptoexchange/clone-script.png"
                    alt="NFT Marketplace Clone Script"
                  />
                  NFT Marketplace Clones
                </div>
                <p className="pharagraph">
                We offer NFT Marketplace clone scripts for you to create an NFT Marketplace exactly identical to popular NFT-based platforms. Our script is secure and acts as an instant solution to enter the market. Along with its effectiveness, our scripts can replicate the entire features and functionalities of an existing NFT Marketplace.
                </p>
              </div>
            </div>
          </div>
      </section>
      </div>
    )
  }
}

export default DevMethod
