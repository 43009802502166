import React from 'react'



class BlockPlat extends React.Component {


  render() {

    return (
      <section className="secureof lucrative centeralign pt-100 mb-0">
        <div className="container">
        <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Blockchain Platforms</span> for </span>
        NFT Marketplace Development Solutions</h2>
          <p className="text-center">We specialize in offering the NFT Marketplace Development in various networks. Our expert team offers multi-compatible NFT Marketplaces also. </p>
          <div className="factor">
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/ethereum.png" alt="Ethereum" />
              <span>Ethereum</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/tron-token.png" alt="Tron" /><span>Tron</span></p>
            </div>
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/binance-smart-chain.png" alt="Binance Smart Chain" /><span>Binance Smart Chain</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/polygon.png" alt="Polygon" /><span>Polygon</span></p>
            </div>
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/solana.png" alt="Solana" /><span>Solana</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/wave.png" alt="Waves" /><span>Waves</span></p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BlockPlat