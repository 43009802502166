import React from 'react'

class TopFeatures extends React.Component {

  render() {
    return (
      <section className="topfeaturez pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Top Features</span>
              We Provide in Our NFT Marketplace Platform</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Bid and Buy</h4>
                <p className="pharagraph">It is a ready-to-launch smart contract audited NFT Marketplace that executes effective buying, selling, and auctioning of digital Collectibles
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img   width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/bid-and-buy.png" alt="Bid and Buy" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img   width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/proven-security.png" alt="Proven Security" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Proven Security</h4>
                <p className="pharagraph">We don’t compromise any part of the security features in a security audit and have successfully passed many advanced security tests to ensure the safety of the customer funds.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Flawless Trading</h4>
                <p className="pharagraph">Our NFT Marketplace development makes sure to provide a flawless experience to the participants from step one so that they are connected to the system in a hassle-free manner!</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img   width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/flawless-trading.png" alt="Flawless Trading" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img   width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/stunning-dashboard.png" alt="Stunning Dashboard" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Stunning Dashboard</h4>
                <p className="pharagraph">Our NFT Marketplace user dashboard is easy to use and manage. Users will be drawn to the game-like features and abilities to level up, gain points, and receive awards, by joining in on the discussion.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Custom UI/UX</h4>
                <p className="pharagraph">Our software has a complete and user-friendly dashboard so that your users can get the best user experience on the platform and explore more.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img   width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nft/custom-ui-ux.png" alt="Custom UI/UX" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures