import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3 d-none d-md-block"><span className="bluecolor">FAQ</span>  </span>
            Frequently Asked Questions
          </h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What is NFT Marketplace Development?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">NFT Marketplace Development is the process of designing and launching a digitalized platform where users can store, buy & sell NFTs. It is one of the best revenue business ideas in the current crypto market.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                How do I create an NFT Marketplace?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">To create an NFT Marketplace, there are two popular development solutions. Entrepreneurs can either develop from scratch or use a clone script to create an NFT Marketplace.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                How much does it cost to build a NFT Marketplace?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The NFT Marketplace development through a clone script costs in the range of $30,000 to $50,000 based on features and functionalities. Instead, if you develop from scratch, the cost comes to around $1,00,000.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                What are the steps in NFT Marketplace Development?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Requirement gathering, Planning, Designing, development, Testing, and deployment are the steps involved in the creation of an NFT Marketplace. These steps can be followed to build a highly valuable NFT Marketplace.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                How to select the best NFT Marketplace Development company?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">The best NFT Marketplace development company should be selected based on factors like Industry experience, Developers team, Client reviews, number of projects, reputation, and the benefits they offer to entrepreneurs.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '6' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('6')} className="panel-toggle" variant="link" eventKey="6">
                Will the NFT Marketplace you build be usable on mobile apps?
                </Accordion.Toggle>  

              </div>
              <Accordion.Collapse eventKey="6">
                <div className="panel-body card-body">Our NFT Marketplace is supported on different extensions including web browsers, mobile apps, tablets etc. Additionally we offer NFT Marketplace for other extensions based on Client’s requirements.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '7' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('7')} className="panel-toggle" variant="link" eventKey="7">
                Which blockchain technologies do you use to create the tokens in the marketplace?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="7">
                <div className="panel-body card-body">The NFT Marketplace we develop supports multiple blockchains including popular ones like  Ethereum, Binance Smart Chain, Polygon, Tron and Hyperledger. The cost can vary based on the blockchain you choose to create tokens.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection