import React from 'react'



class UsecaseOf extends React.Component {


  render() {

    return (
      <div className='pt-100'>
      <section className="usecase">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Use Cases</span> of Developing </span>
                an NFT Marketplace Platform</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="581px" height="509px" src="https://coinsclone.mo.cloudinary.net/images/nft/use-case-of.png" alt="Use Case of NFT Marketplace" />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Easy portability -</b> Digital and real-world assets can be mined into non-fungible tokens and traded using the platform.
              </p>
              <p className="pharagraph" ><b>Massive income potential -</b> Your income can be on autopilot as the members of your NFT Marketplace increase and they trade in non-fungible tokens with greater frequency.
              </p>
              <p className="pharagraph" ><b>Transforming digital art -</b>  The greatest impact of NFT Marketplace has been felt in the arena of digital art may it be graphics, gaming elements, videos, and documents. Art can be sold, bought, and curated in the form of NFTs.
              </p>
              <p className="pharagraph" ><b>Music and audio -</b>  The NFT Marketplaces have had a huge impact on the music industry with various artists directly selling their creations through non-fungible tokens.
              </p>
              <p className="pharagraph" ><b>NFT Marketplace for Fintech -</b>  With micro-payments and smart contracts the fintech industry has made long strides in NFT-based financing and loans.
              </p>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default UsecaseOf