import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class VariousNft extends React.Component {

  render() {
    return (
      <div className="pt-100">
      <section className="devmethod mb-0">
        <div className="container"> 
          <h2 className="heading-h2 text-center">
          Various <span className="bluecolor">NFT Marketplace</span> Development Solutions 
          </h2>
          <p className="text-center">
          We have 9+ years of experience in offering NFT Marketplace Development-related services with 100% customer satisfaction. We also provide various clone scripts for popular NFT Marketplaces below.
          </p>
          <div className="d-lg-flex">
            <div className="square" >
              <a href="https://www.coinsclone.com/opensea-clone-script/">
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/transaction-fees.png"
                  alt="OpenSea like NFT Marketplace"
                />
                <span>OpenSea like NFT Marketplace</span>
              </h3>
              </a>
              <p className="pharagraph">
              With our agile white label solution, you can launch an advanced-level NFT Marketplace similar to the Opensea platform.
              </p>
            </div>
            <div className="square">
              <a href="https://www.coinsclone.com/rarible-clone-script/">
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/registration-fees.png"
                  alt="Rarible like NFT Marketplace"
                />
                <span>Rarible like NFT Marketplace</span>
              </h3>
              </a>  
              <p className="pharagraph">
              The white label solution for Rarible-like platform development that helps you to deploy your NFT trading platform within 7 to 15 days.
              </p>
            </div>
            <div className="square" >
              <a href="https://www.coinsclone.com/superrare-clone-script/">
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/minting-fees.png"
                  alt="SuperRare like NFT Marketplace"
                />
                <span>SuperRare like NFT Marketplace</span>
              </h3>
              </a>
              <p className="pharagraph">
              The features of the SuperRare NFT Marketplace are replicated in our white label solution and also you can customize it.
              </p>
            </div>
          </div>
          <div className="d-lg-flex">
            <div className="square" >
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/listing-fees.png"
                  alt="NFT Marketplace like Foundation"
                />
                <span>NFT Marketplace like Foundation</span>
              </h3>
              <p className="pharagraph">
              Highly-secured NFT marketplace like Foundation can be replicated with our white label solution.
              </p>
            </div>
            <div className="square" >
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/auction-bidding-fee.png"
                  alt="NFT Marketplace like Enjin"
                />
                <span>NFT Marketplace like Enjin</span>
              </h3>
              <p className="pharagraph">
              Launch your NFT marketplace like Enjin and integrate your own add-on modules in the NFT Marketplace.
              </p>
            </div>
          </div>
          <div className="mt-3 text-center">
              <ButtonComponent />
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default VariousNft
